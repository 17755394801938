import { Menu, Stack, TextInput } from '@mantine/core';
import { IconBracketsContain } from '@tabler/icons-react';
import { ActionButton, SynonymsInput } from 'components';
import { useTranslation } from 'react-i18next';
import { MRT_TableOptions } from 'mantine-react-table';
import { Container } from 'stores/wizard';
import { ContainerTableState } from '../ContainerTable.types';

export const testIds = {
  button: 'container-table-actions-button',
  synonyms: {
    icon: 'container-table-synonyms-icon',
    input: 'container-table-synonyms-input',
  },
  description: {
    input: 'container-table-description-input',
  },
};

export const ContainerRowActions: NonNullable<MRT_TableOptions<Container>['renderRowActions']> = ({ row, table }) => {
  const { t } = useTranslation();

  const onChangeSynonyms = (synonyms: string[]) => {
    const { onChange } = table.getState() as ContainerTableState;
    onChange(row.original.id, { synonyms });
  };

  const onChangeDescription = (description: string) => {
    const { onChange } = table.getState() as ContainerTableState;
    onChange(row.original.id, { description });
  };

  return (
    <Menu position='left'>
      <Menu.Target>
        <ActionButton
          className='opacity-25 hover:opacity-100'
          label={t('wizard.steps.labeling.containerTable.actionsButton')}
          data-testid={testIds.button}
        >
          <IconBracketsContain data-testid={testIds.synonyms.icon} />
        </ActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack>
          <SynonymsInput
            label={t('wizard.steps.labeling.containerTable.synonyms.inputTitle', { name: row.original.name })}
            placeholder={t('wizard.steps.labeling.containerTable.synonyms.placeholder')}
            value={row.original.synonyms}
            classNames={{
              input: 'max-h-[100px] overflow-y-auto',
            }}
            w={400}
            onChange={onChangeSynonyms}
            data-testid={testIds.synonyms.input}
          />
          <TextInput
            label={t('wizard.steps.labeling.containerTable.description.title')}
            description={`${row.original.description?.length || 0}/30`}
            value={row.original.description ?? ''}
            maxLength={30}
            onChange={(e) => onChangeDescription(e.currentTarget.value)}
            data-testid={testIds.description.input}
          />
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
